import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import API_URL from "../../redux2";
import Loading from "../Loading";
import NcImage from "../NcImage/NcImage";
import { currencyType, getCarrierLogo } from "../../utils/flightInfo";
import { getMyCompany } from "../../redux2/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import SeatModal from "../SelectSeat/SeatModal";
import ButtonPrimary from "../Button/ButtonPrimary";
import convertNumbThousand from "../../utils/convertNumbThousand";
import {
  getBookingById,
  resetBooking,
} from "../../redux2/booking/bookingSlice";
import { getMe } from "../../redux2/user/userSlice";
import customTime from "../../utils/customTime";
import { getMyEnterprise } from "../../redux2/enterprise/enterpriseSlice";
import {
  FOREIGN_PASSENGER,
  INTERNATIONAL_SYSTEM_FEE,
  LOCAL_SYSTEM_FEE,
  TRIP_TYPE_MULTI_CITY,
  USER_ROLE_AGENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_ENTERPRISE,
  USER_ROLE_MEMBER,
  USER_ROLE_SUPERAGENT,
  WALLET_MINIMUM_AMOUNT,
} from "../../common/constants";
import { getCorporate } from "../../redux2/corporate/corporateSlice";
import PassengerPricing from "./components/PassengerPrice";
import PassengerForm from "./components/PassengerForm";

const CustomerDetail = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const labors = query.get("labors");
  const traceId = query.get("traceId");
  const tripType = query.get("tripType");
  const isLocal = query.get("isLocal");
  const destination = query.get("destination");
  const passengerTypeState = query.get("passengerTypeState");
  const _id = query.get("_id");

  let totalPassengers = 0;
  if (adults) {
    totalPassengers += parseInt(parseInt(adults));
  }
  if (labors) {
    totalPassengers += parseInt(labors);
  }
  if (children) {
    totalPassengers += parseInt(children);
  }
  if (infants) {
    totalPassengers += parseInt(infants);
  }

  const {
    AirPricing,
    AirSegment,
    FlightKey,
    charges,
    bookingRef,
    queryURL,
    isRoundTrip,
    rule,
    currency,
    rateDifference,
  } = location.state;

  const ruleString = JSON.stringify(rule);

  // TODO: if same passenger selected more than once i,e 2 adults

  const { booking, isLoading: bookingLoading } = useSelector(
    (state) => state.booking
  );
  const { user: authUser } = useSelector((state) => state.auth);
  const { company, isLoading, user } = useSelector((state) => state.user);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { corporate, isLoading: corpLoading } = useSelector(
    (state) => state.corporate
  );

  const [seatModal, setSeatModal] = useState(false);
  const [seatIndex, setSeatIndex] = useState(0);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [warning, setWarning] = useState("");
  const [data, setData] = useState([]);
  const [commission, setCommission] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [bookingId, setBookingId] = useState("");
  const [creditDue, setCreditDue] = useState(null);
  const [queueNumber, setQueueNumber] = useState();

  const [formData, setFormData] = useState([
    // {
    //   FirstName: "merry",
    //   MiddleName: "",
    //   LastName: "Doe",
    //   Email: "merry@gmail.com",
    //   phone: "900000007",
    //   Location: "ETH",
    //   Nationality: "",
    //   AreaCode: "251",
    //   CountryCode: "251",
    //   DOB: "2000-02-02",
    //   Gender: "",
    //   Prefix: "",
    // },
    // {
    //   FirstName: "Jane",
    //   MiddleName: "",
    //   LastName: "Joe",
    //   Email: "jane@gmail.com",
    //   phone: "900000008",
    //   Location: "ETH",
    //   Nationality: "",
    //   AreaCode: "251",
    //   CountryCode: "251",
    //   DOB: "2000-01-01",
    //   Gender: "",
    // },
    {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Email: "",
      phone: "",
      Location: "ETH",
      Nationality: "Ethiopia",
      AreaCode: "251",
      CountryCode: "251",
      DOB: "",
      Gender: "",
      Prefix: "",
      OriginId: "",
      ResidentId: "",
      // address
      AddressName: "",
      Street: "",
      City: "",
      State: "",
      PostalCode: "",
      Country: "",
    },
  ]);

  const [passportData, setPassportData] = useState([
    {
      issueDate: "",
      expiryDate: "",
      issueCountry: "",
      passportNumber: "",
      SupplierCode: "",
      CardNumber: "",
      DocumentType: "P",
      DocumentNumber: "",
      ResidentCountry: "ET",
      ProviderCountry: "ET",
      DateOfBirth: "10OCT19",
      passport: "",
      idCard: "",
      certificate: "",
      other: "",
      ssr: [],
      // visa: "",
    },
  ]);

  useEffect(() => {
    if (_id) {
      dispatch(getBookingById(_id));
    }
  }, [dispatch, _id]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    user?.role === USER_ROLE_ENTERPRISE
      ? dispatch(getMyEnterprise())
      : dispatch(getMyCompany());
  }, [dispatch, user?.role]);

  useEffect(() => {
    if (company?.corporate || enterprise?.corporate) {
      dispatch(getCorporate(company?.corporate || enterprise?.corporate));
    } else {
      let id =
        user?.role === USER_ROLE_EMPLOYEE
          ? user?.corporate
          : company?.corporate;
      dispatch(getCorporate(id));
    }
  }, [dispatch, company, user, enterprise]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    // if password expired date is less than 6 months show error
    passportData.forEach((passport) => {
      if (passport.expiryDate) {
        let expiryDate = moment(passport.expiryDate);
        let today = moment();
        let diff = expiryDate.diff(today, "months");
        if (diff < 6) {
          setWarning("Your passport is about to expire");
        } else {
          setWarning("");
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch, passportData]);

  useEffect(() => {
    // if (booking?.data?._id) {
    if (data?.AirReservation && !error) {
      let airReservation = data?.AirReservation;
      let travelers = data?.BookingTraveler;
      let retrievalPnr = data?._attributes?.LocatorCode;
      let reservation = data?.ProviderReservationInfo;
      toast.warning("Reservation Successful");
      navigate(`/checkout/${bookingId}?${queryURL}`, {
        state: {
          charges,
          queryURL,
          travelers,
          systemFee: getSystemFee(),
          retrievalPnr,
          reservation,
          serviceCharge,
          airReservation,
          airPrice: AirPricing,
          currency,
          creditDue,
          rateDifference,
        },
      });
    }
    dispatch(resetBooking());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.AirReservation, error]);

  useEffect(() => {
    if (booking?.passengers) {
      setFormData([]);
      setPassportData([]);
      Array(totalPassengers)
        .fill()
        .map((_, index) => {
          setFormData((prev) => {
            return [
              ...prev,
              {
                Prefix: booking?.passengers[index]?.title,
                FirstName: booking?.passengers[index]?.firstName,
                MiddleName: booking?.passengers[index]?.middleName,
                LastName: booking?.passengers[index]?.lastName,
                Email: booking?.passengers[index]?.email,
                phone: +booking?.passengers[index]?.phoneNumber
                  ?.toString()
                  ?.slice(3),
                Location: booking?.passengers[index]?.location,
                Nationality: booking?.passengers[index]?.nationality,
                DOB: booking?.passengers[index]?.dob?.split("T")[0],
                Gender:
                  booking?.passengers[index]?.gender === "Male" ? "M" : "F",
              },
            ];
          });
          setPassportData((prev) => {
            return [
              ...prev,
              {
                DocumentNumber: booking?.passengers[index]?.passportNumber,
                expiryDate:
                  booking?.passengers[index]?.passportExpiry?.split("T")[0],
              },
            ];
          });
        });
    }
  }, [booking?.passengers, totalPassengers]);

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      [name]: value,
    };
    setFormData(newFormData);

    // setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSSRChange = (e, index, i) => {
    const { value } = e.target;
    const newPassportData = [...passportData];

    if (!newPassportData[index]) {
      newPassportData[index] = {
        issueDate: "",
        expiryDate: "",
        issueCountry: "",
        ssr: [],
      };
    }
    if (!newPassportData[index].ssr) {
      newPassportData[index].ssr = [];
    }
    newPassportData[index].ssr[i] = value;
    setPassportData(newPassportData);
  };

  async function handleAcceptedFiles(e, index) {
    const { name, files } = e.target;
    if (files.length > 3) {
      alert("You can only upload 3 images");
      return;
    }
    if (files[0].size > 1024 * 1024 * 5) {
      alert("Image size should be less than 5MB");
      return;
    }
    const newPassportData = [...passportData];
    newPassportData[index] = {
      ...newPassportData[index],
      [name]: files[0],
      // [name]: await fileToBase64(files[0]),
    };
    setPassportData(newPassportData);
  }

  const onPassportChange = (e, index) => {
    const { name, value } = e.target;
    const newPassportData = [...passportData];
    newPassportData[index] = {
      ...newPassportData[index],
      [name]: value,
    };
    setPassportData(newPassportData);
    // setPassportData({ ...passportData, [e.target.name]: e.target.value });
  };

  function getSystemFee() {
    if (isLocal === "true")
      return LOCAL_SYSTEM_FEE * (+adults + +children + +labors);
    else {
      return user?.role === USER_ROLE_SUPERAGENT ||
        user?.role === USER_ROLE_EMPLOYEE
        ? INTERNATIONAL_SYSTEM_FEE * (+adults + +children + +labors)
        : // : passengerTypeState === NON_DOCUMENT_HOLDER
        currencyType(currency) === "USD"
        ? corporate?.exchangeRate * charges * 0.2
        : charges * 0.2;
    }
  }

  function getServiceCharge() {
    // if (passengerTypeState === NON_DOCUMENT_HOLDER) {
    if (currencyType(currency) === "USD") {
      return charges > 0
        ? charges * corporate?.exchangeRate
        : serviceCharge * corporate?.exchangeRate;
    } else {
      return charges > 0 ? charges : serviceCharge;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      authUser?.role === USER_ROLE_AGENT ||
      authUser?.role === USER_ROLE_MEMBER
    ) {
      if (!company) {
        toast.error("Please Try Again");
        dispatch(getMyCompany());
        return;
      }
    }

    if (
      isLocal === "true" &&
      (user?.role === USER_ROLE_MEMBER || user?.role === USER_ROLE_AGENT)
    ) {
      let hasPassportOrIdCard = false;
      passportData.forEach((data) => {
        if (data?.passport !== "" || data?.idCard !== "") {
          hasPassportOrIdCard = true;
        }
      });
      if (!hasPassportOrIdCard) {
        toast.error("Please upload passport or id card");
        return;
      }
    }

    if (
      enterprise &&
      enterprise?.method === "prePaid" &&
      enterprise?.wallet < WALLET_MINIMUM_AMOUNT + parseFloat(totalPrice())
    ) {
      toast.error(
        "Insufficient balance in your wallet. Please deposit money to continue."
        // "Insufficient Balance. Balance is less than 20,000 birr. Please deposit to your wallet"
      );
      return;
    }

    if (
      user?.role === USER_ROLE_EMPLOYEE &&
      user?.dailyBookingLimit < parseFloat(totalPrice() + user?.dailyBooking)
    ) {
      toast.error("You have reached your daily booking limit.");
      return;
    }

    if (
      (user?.role === USER_ROLE_SUPERAGENT ||
        user?.role === USER_ROLE_EMPLOYEE) &&
      serviceCharge < 1
    ) {
      toast.error("Service Charge must be greater than 0");
      return;
    } else if (
      (user?.role === USER_ROLE_AGENT || user?.role === USER_ROLE_MEMBER) &&
      commission < 1
    ) {
      toast.error("Agent Fee must be greater than 0");
      return;
    }

    let bookingData2 = {
      // Company: "FetanTicket",
      TraceId: traceId,
      TotalAmount: AirPricing._attributes.TotalPrice,
      TicketDate:
        AirSegment instanceof Array
          ? AirSegment[0]._attributes.DepartureTime
          : AirSegment.DepartureTime,
      AirPricingSolution: {
        ...AirPricing,
        AirSegment: FlightKey,
      },
      BookingTraveler: [],
      SpecificSeatAssignment: [],
      // ActionStatus: {
      //   Type: "TTL",
      //   AirportCode: AirSegment?.Origin || AirSegment?.[0]?._attributes?.Origin,
      //   TicketDate:
      //     AirSegment instanceof Array
      //       ? AirSegment[0]._attributes.DepartureTime
      //       : AirSegment.DepartureTime,
      //   SupplierCode:
      //     AirSegment?.Carrier || AirSegment?.[0]?._attributes?.Carrier,
      // },
      OtherInfo: {
        ServiceCharge: getServiceCharge(),
        USDServiceCharge: charges > 0 ? +charges : +serviceCharge,
        Commission: commission,
        SystemFee: getSystemFee(),
        TripType: tripType,
        isRoundTrip: isRoundTrip,
        Destination: destination,
        company: company?._id,
        queueNumber,
        rule: ruleString,
        rateDifference,
        // currency:
        //   passengerTypeState === NON_DOCUMENT_HOLDER &&
        //   (AirSegment.Carrier || AirSegment[0]._attributes.Carrier !== "ET")
        //     ? "USD"
        //     : "ETB",
        currency,
        user: {
          id: user?._id || authUser?._id,
          role: user?.role || authUser?.role,
        },
      },
    };

    for (let passenger in formData) {
      let SSR = passportData[passenger]?.ssr?.filter((item) => {
        return Object.values(item).some((val) => val !== "");
      });
      bookingData2.BookingTraveler.push({
        Prefix: formData[passenger].Prefix,
        FirstName: formData[passenger].FirstName,
        MiddleName: formData[passenger].MiddleName,
        LastName: formData[passenger].LastName,
        Email: formData[passenger].Email,
        OriginId: formData[passenger].OriginId,
        ResidentId: formData[passenger].ResidentId,
        PhoneNumber: {
          AreaCode: "251",
          CountryCode: "251",
          Location: "ETH",
          Number: formData[passenger].phone,
        },
        DOB: formData[passenger].DOB,
        Gender: formData[passenger].Gender || "M",
        Nationality: formData[passenger].Nationality || "Ethiopia",
        TravelerType: bookingRef[passenger]?.Code,
        Key: bookingRef[passenger]?.BookingTravelerRef,
        passportData: passportData[passenger]?.DocumentNumber,
        Price:
          +AirPricing?.AirPricingInfo?.[
            passenger
          ]?._attributes?.TotalPrice?.toString().slice(3) ||
          +AirPricing?.AirPricingInfo?._attributes?.TotalPrice?.toString().slice(
            3
          ),

        LoyaltyCard: {
          CardNumber: passportData[passenger]?.CardNumber,
          SupplierCode: passportData[passenger]?.SupplierCode,
        },
        SSR:
          SSR?.map((item) => {
            if (item !== "") {
              return {
                Type: item,
                FreeText: "",
              };
            }
          }, []) || [],
        Document: {
          // DocumentType: passportData[passenger].DocumentType,
          // ResidentCountry: passportData[passenger].ResidentCountry,
          // ProviderCountry: passportData[passenger].ProviderCountry,
          ExpiryDate: passportData[passenger]?.expiryDate
            ? moment(passportData[passenger]?.expiryDate).format("DDMMMYY")
            : "10OCT30",
          BirthDay: moment(formData[passenger]?.DOB).format("DDMMMYY"),
          DocumentType: "P",
          DocumentNumber:
            passportData[passenger]?.DocumentNumber || "ET1234567",
          ResidentCountry: "ET",
          ProviderCountry: "ET",
        },
        Address: {
          AddressName: formData[passenger]?.AddressName,
          Street: formData[passenger]?.Street,
          City: formData[passenger]?.City,
          State: formData[passenger]?.State,
          PostalCode: formData[passenger]?.PostalCode,
          Country: formData[passenger]?.Country,
        },
      });
    }

    // if (
    //   isLocal === "true" &&
    //   formData.length === 1 &&
    //   FlightKey instanceof Object
    // ) {
    //   bookingData2.SpecificSeatAssignment.push({
    //     PassengerKey: bookingRef?.[0]?.BookingTravelerRef,
    //     SegmentRef: FlightKey?._attributes?.Key,
    //     SeatId: reservedSeats?.[0]?.[0]?.[0]?._attributes?.SeatCode,
    //   });
    // }

    if (reservedSeats?.length > 0) {
      if (FlightKey?.length > 1) {
        for (let i = 0; i < FlightKey.length; i++) {
          for (let k = 0; k < formData.length; k++) {
            if (reservedSeats?.[k]?.[i]?.[0]?._attributes?.SeatCode) {
              bookingData2.SpecificSeatAssignment.push({
                PassengerKey: bookingRef?.[k]?.BookingTravelerRef,
                SegmentRef: FlightKey[i]?._attributes?.Key,
                SeatId: reservedSeats?.[k]?.[i]?.[0]?._attributes?.SeatCode,
              });
            }
          }
        }
      } else {
        for (let i = 0; i < formData.length; i++) {
          if (reservedSeats?.[i]?.[0]?.[0]?._attributes?.SeatCode) {
            bookingData2.SpecificSeatAssignment.push({
              PassengerKey: bookingRef?.[i]?.BookingTravelerRef,
              SegmentRef: FlightKey?._attributes?.Key,
              SeatId: reservedSeats?.[i]?.[0]?.[0]?._attributes?.SeatCode,
            });
          }
        }
      }
    }

    let files = [];
    for (let i = 0; i < passportData.length; i++) {
      if (passportData[i]?.passport) {
        files.push(passportData[i]?.passport);
      }
      if (passportData[i]?.idCard) {
        files.push(passportData[i]?.idCard);
      }

      if (passportData[i]?.certificate) {
        files.push(passportData[i]?.certificate);
      }

      if (passportData[i]?.other) {
        files.push(passportData[i]?.other);
      }
      // if (passportData[i]?.visa) {
      //   files.push(passportData[i]?.visa);
      // }
    }

    const data = new FormData();
    data.append("bookingData", JSON.stringify(bookingData2));
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    setLoading(true);
    axios
      .post(API_URL + "flight/booking", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setBookingId(res.data?.bookingId);
        setCreditDue(res.data?.creditDue);
        setData(res.data?.AirCreateReservationRsp?.UniversalRecord);
        setError(
          res.data?.AirCreateReservationRsp?.ResponseMessage?.[1]?._text
        );
        setError(res.data?.Fault?.faultstring?._text);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message);
      });
  };

  if (loading || bookingLoading || isLoading || corpLoading) {
    return <Loading />;
  }

  function totalPrice() {
    const airPrice = parseFloat(
      AirPricing?._attributes?.TotalPrice?.toString().slice(3)
    );

    let result =
      rateDifference > 1 ? (airPrice * rateDifference).toFixed(2) : airPrice;

    result = parseFloat(result) + charges + parseFloat(serviceCharge || 0);
    return result;
  }

  const isRequired = (isLocal, user, i) => {
    if (
      user?.role === USER_ROLE_SUPERAGENT ||
      user?.role === USER_ROLE_EMPLOYEE
    ) {
      return false;
    }
    if (
      isLocal === "true" &&
      (passportData[i]?.passport || passportData[i]?.idCard)
    ) {
      return false;
    }
    // if (
    //   isLocal === "false"
    //    &&
    //   (AirSegment.Carrier || AirSegment[0]._attributes.Carrier) === "ET"
    // ) {
    //   return false;
    // }
    return true;
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 shadow space-y-6 sm:space-y-8 p-5 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            {/* <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden"> */}
            <NcImage
              src={getCarrierLogo(
                AirSegment.Carrier || AirSegment[0]._attributes.Carrier
              )}
            />
            {/* </div> */}
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <span className="text-lg text-neutral-500 font-semibold">
              <h1>{AirSegment.Carrier || AirSegment[0]._attributes.Carrier}</h1>
            </span>
            {AirSegment instanceof Array ? (
              AirSegment.map((segment, index) => (
                <div key={index}>
                  <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                    <h1>
                      {segment._attributes.Origin} -{" "}
                      {segment._attributes.Destination}
                    </h1>
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    {moment(segment._attributes.DepartureTime).format(
                      "DD MMM YYYY"
                    )}{" "}
                    -
                    {moment(segment._attributes.ArrivalTime).format(
                      "DD MMM YYYY"
                    )}
                  </span>
                  <span className="text-base font-medium mt-1 block">
                    {customTime(segment._attributes.DepartureTime)} -
                    {customTime(segment._attributes.ArrivalTime)}
                  </span>
                </div>
              ))
            ) : (
              <div>
                {/* <span className="text-lg text-neutral-500 font-semibold">
                  <h1>{AirSegment.Carrier}</h1>
                </span> */}
                <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                  <h1>
                    {AirSegment.Origin} - {AirSegment.Destination}
                  </h1>
                </span>
                <span className="text-base font-medium mt-1 block">
                  {moment(AirSegment.DepartureTime).format("DD MMM YYYY")} -
                  {moment(AirSegment.ArrivalTime).format("DD MMM YYYY")}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {customTime(AirSegment.DepartureTime)} -
                  {customTime(AirSegment.ArrivalTime)}
                </span>
              </div>
            )}
            <div className="flex flex-row justify-between">
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {+adults > 0
                  ? parseInt(adults) + "Adult"
                  : parseInt(labors) + "Labors"}
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {children > 0 && children + " Children"}
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {infants > 0 && infants + " Infants"}
              </span>
            </div>

            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div>
            <PassengerPricing
              label={+adults > 0 ? "Adults" : "Labors"}
              count={+adults > 0 ? adults : labors}
              priceIndex={0}
              AirPricing={AirPricing}
              rateDifference={rateDifference}
            />
            <PassengerPricing
              label="Children"
              count={children}
              priceIndex={1}
              AirPricing={AirPricing}
              rateDifference={rateDifference}
            />
            <PassengerPricing
              label="Infants"
              count={infants}
              priceIndex={children > 0 ? 2 : 1}
              AirPricing={AirPricing}
              rateDifference={rateDifference}
            />
          </div>

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            {user?.role === USER_ROLE_SUPERAGENT ||
            user?.role === USER_ROLE_EMPLOYEE ? (
              <span>
                <input
                  type="number"
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  className="w-20 border border-black rounded-md p-1"
                  value={serviceCharge}
                  onChange={(e) => setServiceCharge(e.target.value)}
                  required
                />{" "}
                {currencyType(currency)}
              </span>
            ) : (
              <span className="text-red-500">
                {charges || 0} {currencyType(currency)}
              </span>
            )}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Agent Fee</span>
            {user?.role === USER_ROLE_SUPERAGENT ||
            user?.role === USER_ROLE_EMPLOYEE ||
            user?.role === USER_ROLE_ENTERPRISE ? (
              <span>0 birr</span>
            ) : (
              <span className="text-red-500">
                <input
                  type="number"
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  className="w-20 border border-black rounded-md p-1 text-black"
                  value={commission}
                  onChange={(e) => setCommission(e.target.value)}
                  required
                />{" "}
                {currencyType(currency)}
              </span>
            )}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Queue Number</span>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="w-20 border border-black rounded-md p-1"
              value={queueNumber}
              onChange={(e) => setQueueNumber(e.target.value)}
            />
          </div>
          <div className="border-b border-neutral-200 "></div>
          <div className="flex justify-between font-bold text-xl text-black">
            <span>Total Price</span>
            <span>
              {convertNumbThousand(
                (totalPrice() + parseFloat(commission || 0)).toFixed(2)
              )}{" "}
              {AirPricing?._attributes?.TotalPrice?.substring(0, 3)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = (index, type) => {
    return (
      <PassengerForm
        index={index}
        type={type}
        formData={formData}
        AirSegment={AirSegment}
        passportData={passportData}
        onChange={onChange}
        onPassportChange={onPassportChange}
        handleAcceptedFiles={handleAcceptedFiles}
        onSSRChange={onSSRChange}
        isRequired={isRequired}
        passengerTypeState={passengerTypeState}
        isLocal={isLocal}
        reservedSeats={reservedSeats}
        user={user}
        warning={warning}
        setSeatIndex={setSeatIndex}
        setSeatModal={setSeatModal}
      />
    );
  };

  return (
    <div
      className={`nc-CheckOutPage ${className} px-1 py-4`}
      data-nc-id="CheckOutPage"
    >
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
          {/* <div className="">{renderMain()}</div> */}
          <form onSubmit={onSubmit}>
            {[...Array(totalPassengers)].map((_, index) => {
              return (
                <div className="mb-10">
                  <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 shadow space-y-8 px-5 sm:p-6 xl:p-8 mb-2">
                    <div>
                      <h3 className="text-2xl font-semibold">
                        Passenger {index + 1} Information (
                        {bookingRef?.[index]?.Code})
                      </h3>
                      {/* {company &&
                        (company?.wallet < WALLET_MINIMUM_AMOUNT ||
                          company.wallet < parseFloat(totalPrice())) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            Insufficient balance in your wallet. Please deposit
                            money to continue.
                          </p>
                        )} */}
                      {enterprise &&
                        enterprise?.method === "prePaid" &&
                        enterprise?.wallet <
                          WALLET_MINIMUM_AMOUNT + parseFloat(totalPrice()) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            Insufficient balance in your wallet. Please deposit
                            money to continue.
                          </p>
                        )}
                      {user?.role === USER_ROLE_EMPLOYEE &&
                        user?.dailyBookingLimit <
                          parseFloat(totalPrice() + user?.dailyBooking) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            You have reached your daily booking limit.
                          </p>
                        )}
                      {renderMain(index, bookingRef?.[index]?.Code)}
                    </div>
                  </div>

                  <hr className="my-5" />
                </div>
              );
            })}
            <div className="flex justify-end pb-3">
              <ButtonPrimary
                className="hover:text-white hover:bg-blue-600 block w-72 md:w-48"
                type="submit"
              >
                Continue
              </ButtonPrimary>
            </div>
          </form>
        </div>
        <div className=" lg:block flex-grow mb-10 ">{renderSidebar()}</div>
      </main>
      {seatModal && (
        <SeatModal
          seatModal={seatModal}
          setSeatModal={setSeatModal}
          reservedSeats={reservedSeats}
          setReservedSeats={setReservedSeats}
          airSegment={AirSegment}
          traceId={traceId}
          index={seatIndex}
        />
      )}
    </div>
  );
};

export default CustomerDetail;
